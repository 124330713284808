import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { HOST_PREFIX } from "../../Network/ApiPaths";

class ExpensesComponent extends React.Component {
  constructor(props) {
    super()

  }

  render() {
    return (
      <div>
        <Dropdown data={this.props} />
        {
          this.props.componentPage === "todayexpenses" ? <Todayexpenses data={this.props} /> : <Addexpenses data={this.props} />
        }
      </div>
    )
  }
}


function Dropdown(props) {
  const {
    expensesCategories,
    handleCategoryChange,
    expensesSubCategories,
    subCatSelectVal,
    handleSubCategoryChange,
    showSubCategoryList,
    addAllExepnsesdata,
    showSubCarError,
    showCatError,
    expCategoryValue
  } = props.data
  return (
    <div className="main_media">
      <div className="dropdown_media">
        <select onChange={handleCategoryChange} value={expCategoryValue} className="form-control" name="expcat" id="expcat">
          <option value="" selected disabled>Select Category</option>

          {
            expensesCategories && expensesCategories !== '' && expensesCategories !== null ? expensesCategories.map((item, index) => {
              return (<option className={item.subcategory} key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
            }) : <option value="" disabled>No Details Found</option>
          }

        </select>
        {/* {
          showCatError ?
            <span >Please Select Expenses Category</span> : null
        }
        {
          showSubCarError ?
            <span>Please Select Expenses Sub Category</span> : null
        } */}
      </div>

      {
        showSubCategoryList ?

          (<div >
            <select onChange={handleSubCategoryChange} className="form-control" name="expcat" id="expcat" value={subCatSelectVal}>
              <option value="" selected disabled>Select Sub Category</option>
              {
                expensesSubCategories && expensesSubCategories !== '' && expensesSubCategories !== null ? expensesSubCategories.map((item, index) => {
                  return (<option key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                }) : <option value="" disabled>No Details Found</option>
              }
            </select>
          </div>) : null
      }

      <button className="btn btn-primary dropdown_btn_media" onClick={addAllExepnsesdata}>
        Add Expenses
      </button>
    </div>
  )
}

function Todayexpenses(props) {
  const { todayExpenses, totalCost, deleteExpense, updateEditFile, updateExpenses, file } = props.data
  const [values, setValues] = useState(
    {
      name: "",
      quantity: "",
      unit: "",
      cost: "",
      total_cost: "",
      bill_image: "",
      id: "",
      delete_id: "",
    }
  );

  function upadteList(data) {
    setValues({
      ...values,
      name: data.name,
      quantity: data.quantity,
      unit: data.unit,
      cost: data.cost,
      total_cost: data.total_cost,
      bill_image: data.bill_image,
      id: data.id,
    })
  }

  function handleCost(e) {
    setValues({ ...values, cost: e.target.value })
  }
  function handleQuantity(e) {
    setValues({ ...values, quantity: e.target.value})
  }

  function calcCost(){
    setValues({...values, total_cost: parseFloat(values.cost) *  parseFloat(values.quantity)})
  }

  return (
    <div>
      <div
        id="editExpense"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="exampleModalLabel" className="modal-title">
                Update Expense
              </h4>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    // onChange={this.handleName}
                    placeholder="Expense Item"
                    className="form-control"
                    id="updateInput"
                    value={values.name}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Quantity</label>
                  <input
                    type="text"
                    maxLength={6}
                    onKeyPress={(event) => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key) || (event.target.value.includes('.')) && event.key === '.') {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleQuantity}
                    onKeyUp={calcCost}
                    placeholder="Quantity"
                    className="form-control"
                    id="updateInput"
                    value={values.quantity}
                  />
                </div>
                <div className="form-group">
                  <label>Unit</label>
                  <input
                    type="text"
                    // onChange={this.handleQuantity}
                    placeholder="Unit"
                    className="form-control"
                    id="updateInput"
                    value={values.unit}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Cost</label>
                  <input
                    type="text"
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key) || (event.target.value.includes('.')) && event.key === '.') {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleCost}
                    onKeyUp={calcCost}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                    value={values.cost}
                  />
                </div>
                <div className="form-group">
                  <label>Total Cost</label>
                  <input
                    type="text"
                    // onChange={this.handleCost}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                    value={parseFloat(values.total_cost).toFixed(2)}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Bill Image</label>
                  <input
                    type="file"
                    onChange={updateEditFile}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                  />
                  <a href={`${HOST_PREFIX}images/expenses/${values.id}/${values.bill_image}`} target="_blank">{values.bill_image}</a>
                </div>
                <div
                  style={{ display: "inline-block" }}
                  className="form-group"
                  onClick={(e) => { e.preventDefault(); updateExpenses(values) }}
                  data-dismiss="modal"
                ><input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="deleteExpense"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 id="exampleModalLabel" className="modal-title">
                Are you Sure
              </h4>
            </div>
            <div className="modal-body">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button className="btn btn-primary"
                  onClick={() => { deleteExpense(values.delete_id) }}
                  data-dismiss="modal">
                  Delete
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-success"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-header d-flex align-items-center expenses-headings">
        <h3 >Today Expenses</h3>
        <h4 >Today Total Expense: <span style={{ color: "green" }}>{totalCost === null || totalCost === '' || totalCost === undefined ? '00.00' : parseFloat(totalCost).toFixed(2)} Rupees</span></h4>
      </div>
      <div className="card-body" style={{ padding: 0, height: 360, overflow: "auto" }}>
        <div className="table-responsive expenseTable">
          <table className="table" style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>SL.No</th>
                <th>Name</th>
                <th>Qunatity</th>
                <th>unit</th>
                <th>cost</th>
                <th>total Cost</th>
                <th>Bill Image</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {todayExpenses
                ?
                todayExpenses !== null &&
                  todayExpenses !== undefined
                  ? todayExpenses.map((value, index) => (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{value.name}</td>
                      <td>{value.quantity}</td>
                      <td style={{ textAlign: "center" }}>{value.unit}</td>
                      <td style={{ textAlign: "center" }}>{value.cost}</td>
                      <td style={{ textAlign: "center" }}>{parseFloat(value.total_cost).toFixed(2)}</td>
                      <td style={{ textAlign: "center" }}><a href={`${HOST_PREFIX}images/expenses/${value.id}/${value.bill_image}`} target="_blank">{value.bill_image}</a></td>
                      <td style={{ textAlign: "center" }}>
                        {(() => {
                          const dateTimeString = value.created_time;
                          const dateTime = new Date(dateTimeString);
                          const hours = dateTime.getHours();
                          const minutes = dateTime.getMinutes();
                          const ampm = hours >= 12 ? 'PM' : 'AM';
                          const hours12 = hours % 12 || 12;
                          const formattedTime = `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                          return formattedTime;
                        })()}
                      </td>
                      <td
                        id={value.id}
                        data-toggle="modal"
                        onClick={() => { upadteList(value) }}
                        data-target="#editExpense"
                        className="fa fa-edit fa-lg"
                      >

                      </td>
                      <td
                        id={value.id}
                        data-toggle="modal"
                        data-target="#deleteExpense"
                        className="fa fa-trash-o fa-lg"
                        onClick={() => { setValues({ ...values, delete_id: value.id }) }}
                      >

                      </td>
                    </tr>
                  ))
                  : todayExpenses
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

let totalAmount = 0;
function Addexpenses(props) {
  const { addAllExpensesData, updateFile, expenseList, finalSubmit } = props.data;
  const [rows, setRows] = useState([
    {
      slno: 1,
      name: "",
      quantity: "",
      unit: "",
      cost: "",
      totalCost: "00.00",
      dataAdded: false,
    },
  ]);

  const [totalSum, setTotalSum] = useState(0)

  const addNewRow = () => {
    // Check if any row has not had data added
    const hasUnaddedData = rows.some((row) => !row.dataAdded);

    if (hasUnaddedData) {
      alert("First add all the row data and save it.");
      return;
    }

    const newRow = {
      slno: rows.length + 1,
      name: "",
      quantity: "",
      unit: "",
      cost: "",
      totalCost: "00.00",
      dataAdded: false,
    };
    setRows([...rows, newRow]);
  };


  const calculatePrice = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    const qty = parseFloat(updatedRows[index].quantity);
    const cost = parseFloat(updatedRows[index].cost);
    if (!isNaN(qty) && !isNaN(cost)) {
      updatedRows[index].totalCost = (qty * cost).toFixed(2);
    } else {
      updatedRows[index].totalCost = "00.00";
    }

    setRows(updatedRows);
  };

  const addDataForRow = async (index) => {
    const updatedRows = [...rows];
    const row = updatedRows[index];

    // Validate the "Name," "Quantity," and "Cost" fields
    if (!row.name || !row.quantity || !row.cost) {
      alert("Please fill in all the details.");
      return;
    }

    row.dataAdded = true;
    setRows(updatedRows);

    const resultData = await addAllExpensesData(row);

    if (resultData) {
      row.dataAdded = true;
      setRows(updatedRows);
    }

    totalAmount = totalAmount + (parseFloat(row.cost) * parseFloat(row.quantity));
    setTotalSum(totalAmount);
  };


  return (
    <div style={{ overflow: "auto" }}>
      <button type="button" onClick={addNewRow} className="btn btn-success">
        Add More+
      </button>
      <div>
        
      </div>
      <div style={{ overflow: "auto", height: 350 }}>
        <table className="table expDataTable" style={{ marginTop: 15 }}>
          <thead>
            <tr>
              <th>SL No</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Cost</th>
              <th>Total Cost</th>
              <th>Upload Bill</th>
              <th>Add Data</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.slno}</td>
                <td>
                  <select
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      calculatePrice(index, "name", selectedValue);
                      const selectedItemId = selectedValue.split('/')[0];
                      const selectedExpense = expenseList.find((item) => item.id === selectedItemId);
                      const unit = selectedExpense ? selectedExpense.unit : '';
                      calculatePrice(index, "unit", unit);
                      setRows((prevRows) => {
                        const updatedRows = [...prevRows];
                        updatedRows[index].unit = unit;
                        return updatedRows;
                      });
                    }}
                    disabled={row.dataAdded}
                  >
                    <option value="" disabled selected>
                      Select Expense
                    </option>
                    {
                      expenseList !== '' &&
                        expenseList !== null &&
                        expenseList !== undefined ? (
                        expenseList.map((item, index) => {
                          return (
                            <option
                              key={index}
                              style={{ color: "black" }}
                              value={item.id + '/' + item.name}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="" disabled>
                          No Details Found
                        </option>
                      )}
                  </select>
                </td>

                <td>
                  <input
                    type="text"
                    maxLength={6}
                    placeholder="Item Quantity"
                    onKeyPress={(event) => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key) || (event.target.value.includes('.')) && event.key === '.') {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) =>
                      calculatePrice(index, "quantity", e.target.value)
                    }
                    disabled={row.dataAdded}
                  />
                </td>
                <td>{row.unit}</td>
                <td>
                  <input
                    type="text"
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/^[0-9]*\.?[0-9]*$/.test(event.key) || (event.target.value.includes('.')) && event.key === '.') {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => calculatePrice(index, "cost", e.target.value)}
                    placeholder="Enter Cost"
                    disabled={row.dataAdded}
                  />
                </td>
                <td>{row.totalCost}</td>
                <td>
                  <input type="file" id="billfile" onChange={updateFile} />
                </td>
                <td>
                  <button
                    style={{
                      padding: "3px 7px",
                      fontSize: "14px",
                      borderRadius: "5px",
                      background: row.dataAdded ? "green" : "red",
                      color: "white",
                      border: "none"
                    }}
                    onClick={() => addDataForRow(index)}
                    disabled={row.dataAdded}
                  >
                    {row.dataAdded ? "Added" : "Add Data"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>
        <div style={{ marginLeft: 10 }}>
          Total Amount : <span>{totalSum} Rupees</span>
        </div>
        <div style={{ marginRight: 10 }}>
          <button onClick={finalSubmit} className="btn btn-primary">
            Close
          </button>
        </div>
      </div>
    </div>
  );
}


export default ExpensesComponent