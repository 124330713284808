import React from "react";
import ExpensesComponent from "./ExpensesComponent";
import Uri from "../../Network/Uri";
import { ADD_EXPENSES_CATEGORY, ADD_EXPENSES_ITEM, ADD_EXPENSES_SUB_CATEGORY, DELETE_EXPENSES_CATEGORY, DELETE_EXPENSES_ITEMS, DELETE_EXPENSES_SUB_CATEGORY, EXPENSE_CATEGORY_DATA, EXPENSE_CATEGORY_ITEM_DATA, EXPENSE_CATEGORY_LIST, EXPENSE_CATEGORY_ONLY_LIST, EXPENSE_SUB_CATEGORY_DATA, EXPENSE_SUB_CATEGORY_ITEM_DATA, EXPENSE_SUB_CATEGORY_LIST, UPDATE_EXPENSE_CATEGORY, UPDATE_EXPENSE_ITEMS, UPDATE_EXPENSE_SUB_CATEGORY } from "../../Network/ApiPaths";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
import ExpenseCategoryComponent from "./ExpenseCategoryComponent";
import ExpenseSubCategoryComponent from "./ExpenseSubCategoryComponent";

let storeFresh;
let clientHost;

class ExpensesContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      categories: [],
      reset: false,
      test: false,
      cName: "",
      subName: "",
      livePage: 1,
      addedCat: false,
      addedSubCat: false,
      addedItem: false,
      editedCat: false,
      selectedCat: [],
      newName: "",
      deletedCatId: "",
      deletedCat: false,
      optionChecked: true,
      subcatChecked: true,
      subCatOptionChecked: true,
      subCatOption: "",
      subCategory: "",
      expCategories: "",
      subCategoryExists: "NO",
      showSubDetails: false,
      ItemChecked: true,
      unitValue: "",
      SubCatdata: [],
      expCategoryData: [],
      expSubCategoryData: [],
      expenseItemData: [],
      gettingSubCategoryData: "",
      itemName: "",
      itemExpCategories: "",
      ItemSubCategoryExists: "NO",
      itemSubCategory: "",
      itemShowSubDetails: false,
      itemSubCatData: [],
      Onlycategories: []
    };
  }
  handleCheckboxChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.checked;

    this.setState({
      [name]: value
    });
  }

  get_set_SubCategory = (event) => {
    let selectedOption = event.target.options[event.target.selectedIndex];
    this.setState({
      expCategories: event.target.value,
      subCategoryExists: selectedOption.className,
      subCategory: ""
    });
    if (this.state.subCategoryExists === 'YES') {
      this.setState({
        showSubDetails: true,
      });
    } else {
      this.setState({
        showSubDetails: false,
      });
    }

  }

  getExpensesItems = (event) => {
    let selectedOption = event.target.options[event.target.selectedIndex];
    this.setState({
      itemExpCategories: event.target.value,
      ItemSubCategoryExists: selectedOption.className,
      itemSubCategory: ""
    })
    if (this.state.ItemSubCategoryExists === 'YES') {
      this.setState({
        itemShowSubDetails: true,
      });
    } else {
      this.setState({
        itemShowSubDetails: false,
      });
    }
  }

  getSubCategoryData = (event) => {
    this.setState({
      gettingSubCategoryData: event.target.value
    });
  }
  setUnit = (event) => {
    this.setState({
      unitValue: event.target.value
    });
  }
  onItemNameChange = (event) => {
    this.setState({
      itemName: event.target.value
    });
  }

  setSubCategory = (event) => {
    this.setState({
      subCategory: event.target.value
    });
  }
  itemSetSubCategory = (event) => {
    this.setState({
      itemSubCategory: event.target.value
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expCategories !== this.state.expCategories) {
      this.getExpenseSubCategories();
      if (this.state.subCategoryExists === 'YES') {
        this.setState({
          showSubDetails: true,
          subCategory: ""
        });
      } else {
        this.setState({
          showSubDetails: false,
          subCategory: ""
        });
      }
    }
    if (prevState.itemExpCategories !== this.state.itemExpCategories) {
      this.getItemExpenseSubCategories();
      if (this.state.ItemSubCategoryExists === 'YES') {
        this.setState({
          itemShowSubDetails: true,
        });
      } else {
        this.getAllExpenseItemData();
        this.setState({
          itemShowSubDetails: false,
        });
      }
    }
    if (prevState.gettingSubCategoryData !== this.state.gettingSubCategoryData) {
      this.getAllExpenseSubCategoriesData();
    }
    if (prevState.itemSubCategory !== this.state.itemSubCategory) {
      this.getAllExpenseItemData();
    }
  }

  onNameChange = (e) => {
    this.setState({ cName: e.target.value });
  };
  onSubNameChange = (e) => {
    this.setState({ subName: e.target.value });
  };
  optionChange = (e) => {
    this.setState({ subCatOption: e.target.value });
  };
  onAdd = (e) => {
    e.preventDefault();
    this.setState({
      test: true,
      reset: true,
      cName: this.state.cName,
      optionChecked: true,
      subcatChecked: true
    });
    // POST API WITH DATA
    const requestBody = {
      name: this.state.cName,
      subcategory: this.state.subcatChecked ? "YES" : "NO",
      status: this.state.optionChecked ? "ACTIVE" : "INACTIVE",
      store_id: storeFresh.storeId,
    };
    postClientDataResponse(
      clientHost + ADD_EXPENSES_CATEGORY,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseCategoriesData();
        this.getExpenseCategories();
        this.setState({
          addedCat: false,
          cName: ""
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  addSubCategory = (e) => {

    e.preventDefault();
    this.setState({
      test: true,
      reset: true,
      subName: this.state.subName,
      subCatOptionChecked: true,
    });
    // // POST API WITH DATA
    const requestBody = {
      name: this.state.subName,
      status: this.state.subCatOptionChecked ? "ACTIVE" : "INACTIVE",
      category_id: this.state.subCatOption,
    };
    postClientDataResponse(
      clientHost + ADD_EXPENSES_SUB_CATEGORY,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseSubCategoriesData();
        this.getAllExpenseCategoriesData();
        this.setState({
          addedSubCat: false,
          subName : ""

        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };

  addItemData = (e) => {
    e.preventDefault();
    // POST API WITH DATA
    const requestBody = {
      name: this.state.itemName,
      unit: this.state.unitValue,
      status: this.state.ItemChecked ? "ACTIVE" : "INACTIVE",
      category_id: this.state.expCategories,
      subcategory_id: this.state.subCategory !== '' ? this.state.subCategory : null,
    };
    postClientDataResponse(
      clientHost + ADD_EXPENSES_ITEM,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseItemData();
        this.setState({
          subCategoryExists: "NO",
          showSubDetails: false,
          itemName: "",
          subCategory: "",
          expCategories: "",
          unitValue: ""
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };

  getExpenseCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_LIST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          reset: false,
          categories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getExpenseCategoriesOnly = () => {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_ONLY_LIST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          Onlycategories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getExpenseSubCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_LIST)
      .addQueryParam("category_id", this.state.expCategories)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          SubCatdata: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getItemExpenseSubCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_LIST)
      .addQueryParam("category_id", this.state.itemExpCategories)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          itemSubCatData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getAllExpenseCategoriesData = () => {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_DATA)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expCategoryData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getAllExpenseSubCategoriesData = () => {
    var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_DATA)
      .addQueryParam("category_id", this.state.gettingSubCategoryData)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expSubCategoryData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }


  getAllExpenseItemData = () => {
    if (this.state.ItemSubCategoryExists === 'YES') {
      var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_ITEM_DATA)
        .addQueryParam("category_id", this.state.itemExpCategories)
        .addQueryParam("subcat_id", this.state.itemSubCategory);
    } else {
      var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_ITEM_DATA)
        .addQueryParam("category_id", this.state.itemExpCategories)
    }
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expenseItemData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  updateCategory = (data) => {
    const requestBody = {
      name: data.name,
      subcategory: data.hasSubCat,
      status: data.status,
      category_id: data.category_id
    };
    postClientDataResponse(
      clientHost + UPDATE_EXPENSE_CATEGORY,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseCategoriesData();
        this.getExpenseCategories();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
  updateSubCategory = (data) => {
    const requestBody = {
      name: data.name,
      status: data.status,
      subCategory_id: data.subCat_id
    };
    postClientDataResponse(
      clientHost + UPDATE_EXPENSE_SUB_CATEGORY,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseSubCategoriesData();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  updateItems = (data) => {
    const requestBody = {
      name: data.name,
      status: data.status,
      item_id: data.item_id,
      unit: data.unit
    };
    postClientDataResponse(
      clientHost + UPDATE_EXPENSE_ITEMS,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getAllExpenseItemData();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  deleteCategory = (data) => {
    var apiPath = new Uri(clientHost + DELETE_EXPENSES_CATEGORY).addQueryParam(
      "id",
      data
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getAllExpenseCategoriesData();
        this.getExpenseCategories();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  deleteSubCategory = (data) => {
    var apiPath = new Uri(clientHost + DELETE_EXPENSES_SUB_CATEGORY).addQueryParam(
      "id",
      data
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getAllExpenseSubCategoriesData();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  deleteItem = (data) => {
    var apiPath = new Uri(clientHost + DELETE_EXPENSES_ITEMS).addQueryParam(
      "id",
      data
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getAllExpenseItemData();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  async componentDidMount() {
    this.getExpenseCategories();
    this.getAllExpenseCategoriesData();
    this.getExpenseCategoriesOnly();
  }
  render() {
    return (
      <>
        {this.props.expenses ?
          <ExpensesComponent
            data={this.state.categories}
            expCategoryData={this.state.expCategoryData}
            itemShowSubDetails={this.state.itemShowSubDetails}
            SubCatdata={this.state.SubCatdata}
            nameChange={this.onNameChange}
            onItemNameChange={this.onItemNameChange}
            onSubNameChange={this.onSubNameChange}
            itemSetSubCategory={this.itemSetSubCategory}
            optionChange={this.optionChange}
            setSubCategory={this.setSubCategory}
            setUnit={this.setUnit}
            subCategory={this.state.subCategory}
            add={this.onAdd}
            itemSubCategory={this.state.itemSubCategory}
            expenseItemData={this.state.expenseItemData}
            addItemData={this.addItemData}
            addedCat={this.state.addedCat}
            addedSubCat={this.state.addedSubCat}
            addedItem={this.state.addedItem}
            reset={this.state.reset}
            optionChecked={this.state.optionChecked}
            subcatChecked={this.state.subcatChecked}
            ItemChecked={this.state.ItemChecked}
            subCatOptionChecked={this.state.subCatOptionChecked}
            handleCheckboxChange={this.handleCheckboxChange}
            addSubCategory={this.addSubCategory}
            get_set_SubCategory={this.get_set_SubCategory}
            getExpensesItems={this.getExpensesItems}
            showSubDetails={this.state.showSubDetails}
            updateItems={this.updateItems}
            deleteItem={this.deleteItem}
            itemSubCatData={this.state.itemSubCatData}
            itemExpCategories={this.state.itemExpCategories}
          />
          : null}
        {this.props.expenseCat ?
          <ExpenseCategoryComponent
            data={this.state.categories}
            expCategoryData={this.state.expCategoryData}
            SubCatdata={this.state.SubCatdata}
            nameChange={this.onNameChange}
            onItemNameChange={this.onItemNameChange}
            onSubNameChange={this.onSubNameChange}
            setSubCategory={this.setSubCategory}
            optionChange={this.optionChange}
            setUnit={this.setUnit}
            add={this.onAdd}
            updateCategory={this.updateCategory}
            addItemData={this.addItemData}
            addedCat={this.state.addedCat}
            addedSubCat={this.state.addedSubCat}
            addedItem={this.state.addedItem}
            reset={this.state.reset}
            optionChecked={this.state.optionChecked}
            subcatChecked={this.state.subcatChecked}
            ItemChecked={this.state.ItemChecked}
            subCatOptionChecked={this.state.subCatOptionChecked}
            handleCheckboxChange={this.handleCheckboxChange}
            addSubCategory={this.addSubCategory}
            get_set_SubCategory={this.get_set_SubCategory}
            showSubDetails={this.state.showSubDetails}
            deleteCategory={this.deleteCategory}
          />
          : null}
        {this.props.expenseSubCat ?
          <ExpenseSubCategoryComponent
            data={this.state.categories}
            expSubCategoryData={this.state.expSubCategoryData}
            SubCatdata={this.state.SubCatdata}
            nameChange={this.onNameChange}
            onItemNameChange={this.onItemNameChange}
            onSubNameChange={this.onSubNameChange}
            setSubCategory={this.setSubCategory}
            optionChange={this.optionChange}
            setUnit={this.setUnit}
            add={this.onAdd}
            addItemData={this.addItemData}
            addedCat={this.state.addedCat}
            addedSubCat={this.state.addedSubCat}
            addedItem={this.state.addedItem}
            reset={this.state.reset}
            optionChecked={this.state.optionChecked}
            subcatChecked={this.state.subcatChecked}
            ItemChecked={this.state.ItemChecked}
            subCatOptionChecked={this.state.subCatOptionChecked}
            handleCheckboxChange={this.handleCheckboxChange}
            addSubCategory={this.addSubCategory}
            get_set_SubCategory={this.get_set_SubCategory}
            SubCategoryData={this.state.Onlycategories}
            getSubCategoryData={this.getSubCategoryData}
            showSubDetails={this.state.showSubDetails}
            updateSubCategory={this.updateSubCategory}
            deleteSubCategory={this.deleteSubCategory}
          />
          : null}
      </>
    )
  }
}

export default ExpensesContainer;