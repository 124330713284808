import React from "react";
import Footer from "./Footer";
import Root from "../Components/Root";
import Employees from "../Components/Employees";
import Delivery from "../Components/Delivery";
import Users from "../Components/Users";
import Categories from "../Components/Categories";
import SubCategories from "../Components/SubCategories";
import Reports from "../Components/Reports/Reports";
import ItemReports from "../Components/ItemReports";
import Expenses from "../Components/Expenses"
import CategoryReports from "../Components/CategoryReports";
import BillReports from "../Components/BillReports";
import ExecutiveReports from "../Components/ExecutiveReports";
import TodaysReport from "../Components/TodaysReport";
import UserReports from "../Components/UserReports";
import BestReports from "../Components/BestReports";
import Products from "../Components/Products";
import Banner from "../Components/Banner";
import AppVersions from "../Components/AppVersions";
import Pincodes from "../Components/Pincodes";
import Stores from "../Components/Stores";
import Promos from "../Components/Promos";
// import Deals from "../Components/Deals/Deals";
import Offers from "../Components/Offers/Offers";
import Subscriptions from "../Components/Subscriptions/Subscriptions";
import TakeOrder from "../Components/TakeOrder/TakeOrder";
import TakeOrder2 from "../Components/TakeOrder/TakeOrder2";
import Orders from "../Components/Orders/Orders";
import ExpensesReportContainer from "./Expenses Reports/ExpensesReportcontainer";
import ExpensesContainer from "./ExpensesStore/ExpensesContainer";
class AdminDash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      home: true,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    };
  }
  showTakeOrder = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: true,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showOrders = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: true,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showHome = (e) => {
    e.preventDefault();
    this.setState({
      home: true,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showDeals = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: true,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showOffers = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: true,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showSubscriptions = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: true,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showDelivery = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: true,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showAppUsers = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: true,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showEmployees = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: true,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: true,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showSubCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: true,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: true,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showItemReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: true,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showCategoryReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: true,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showBillReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: true,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showExecReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: true,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showSuperAdminHourlyReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: true,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showUserReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: true,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showBestReports = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: true,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showProducts = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: true,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showBanner = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: true,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showAppVersions = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: true,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showPincodes = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: true,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showStores = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: true,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showPromos = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: true,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };
  showExpenesReport = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: true,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  };

  showExpenses = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: true,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: false
    });
  }
  showExpenseCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: true,
      expenseSubCat: false,
      expensesItems: false
    });
  }
  showExpenseSubCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: true,
      expensesItems: false
    });
  }
  showExpenseItems = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      employees: false,
      delivery: false,
      users: false,
      categories: false,
      subCategories: false,
      reports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      userReports: false,
      bestReports: false,
      products: false,
      banner: false,
      appVersions: false,
      pincodes: false,
      stores: false,
      promos: false,
      showDeals: false,
      offers: false,
      subscriptions: false,
      takeOrder: false,
      orders: false,
      expensesReport: false,
      expensesMain: false,
      expenseCat: false,
      expenseSubCat: false,
      expensesItems: true
    });
  }

  render() {
    const userCheck = window.localStorage.getItem("access");
    return (
      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          <div className="sidebar-header d-flex align-items-center">
            <div className="avatar">
              <img
                src="img/brand_logo.jpg"
                alt="..."
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="title">
              <h1 className="h4">{JSON.parse(userCheck).name}</h1>
              <p>{JSON.parse(userCheck).role}</p>
            </div>
          </div>
          <ul className="list-unstyled">
            <li
              onClick={this.showHome}
              className={this.state.home ? "active" : null}
            >
              <a href="#/">
                <i className="fa fa-home"></i>Home
              </a>
            </li>
            <li
              onClick={this.showTakeOrder}
              className={this.state.takeOrder ? "active" : null}
            >
              <a href="#/">
                <i className="fa fa-shopping-cart"></i>Take Order
              </a>
            </li>
            <li
              onClick={this.showOrders}
              className={this.state.orders ? "active" : null}
            >
              <a href="#/">
                <i className="fa fa-shopping-bag"></i>Orders
              </a>
            </li>
            <li
              onClick={this.showDeals}
              className={this.state.showDeals ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Deals
              </a>
            </li>
            {/*  <li
              onClick={this.showAppUsers}
              className={this.state.users ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>CRM
              </a>
            </li>
            <li>
              <a
                href="#exampledropdownDropdown1"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="icon-interface-windows"></i>Organization
              </a>
              <ul
                id="exampledropdownDropdown1"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showEmployees}
                  className={this.state.employees ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-interface-windows"></i>Employees
                  </a>
                </li>
                <li
                  onClick={this.showDelivery}
                  className={this.state.delivery ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-interface-windows"></i>Delivery Boys
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li
              onClick={this.showCategories}
              className={this.state.categories ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Categories
              </a>
            </li>
            <li
              onClick={this.showSubCategories}
              className={this.state.subCategories ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Sub Categories
              </a>
            </li> */}
            <li
              onClick={this.showExpenses}
              className={this.state.expensesMain ? "active" : null}
            >
              <a href="#/">
                <i className="fa fa-money"></i>Expenses
              </a>
            </li>
            <li
                  onClick={this.showExpenseItems}
                  className={this.state.expensesItems ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Add Expense Items
                  </a>
                </li>
            {/* <li>
              <a
                href="#exampledropdownDropdown6"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="fa fa-money"></i>Expenses
              </a>
              <ul
                id="exampledropdownDropdown6"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showExpenses}
                  className={this.state.expensesMain ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-grid"></i>Add Expenses
                  </a>
                </li>
                <li
                  onClick={this.showExpenseCategories}
                  className={this.state.expenseCat ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Categories
                  </a>
                </li>
                <li
                  onClick={this.showExpenseSubCategories}
                  className={this.state.expenseSubCat ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>SubCategories
                  </a>
                </li>
                <li
                  onClick={this.showExpenseItems}
                  className={this.state.expensesItems ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Items
                  </a>
                </li>
              </ul>
            </li> */}
            <li>
              <a
                href="#exampledropdownDropdown5"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="fa fa-file-text"></i>Reports
              </a>
              <ul
                id="exampledropdownDropdown5"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showReports}
                  className={this.state.reports ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-bar-chart"></i>Analytics
                  </a>
                </li>
                <li
                  onClick={this.showItemReports}
                  className={this.state.itemReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Item Wise Reports
                  </a>
                </li>
                <li
                  onClick={this.showExpenesReport}
                  className={this.state.expensesReport ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Expenses Reports
                  </a>
                </li>
                <li
                  onClick={this.showCategoryReports}
                  className={this.state.categoryReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Category Reports
                  </a>
                </li>
                <li
                  onClick={this.showBillReports}
                  className={this.state.billReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Bill Wise Reports
                  </a>
                </li>
                <li
                  onClick={this.showExecReports}
                  className={this.state.execReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Executive Reports
                  </a>
                </li>
                <li
                  onClick={this.showSuperAdminHourlyReports}
                  className={this.state.hourlyReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Hourly Reports
                  </a>
                </li>
                {/* <li
                  onClick={this.showUserReports}
                  className={this.state.userReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>CRM Reports
                  </a>
                </li>
                <li
                  onClick={this.showBestReports}
                  className={this.state.bestReports ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Best Seller
                  </a>
                </li> */}
              </ul>
            </li>
            {/* <li
              onClick={this.showProducts}
              className={this.state.products ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Products
              </a>
            </li> */}
            {/* <li>
              <a
                href="#exampledropdownDropdown3"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="icon-interface-windows"></i>Util
              </a>
              <ul
                id="exampledropdownDropdown3"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showBanner}
                  className={this.state.banner ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-bar-chart"></i>Banner Management
                  </a>
                </li>
                <li
                  onClick={this.showAppVersions}
                  className={this.state.appVersions ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-bar-chart"></i>App Versions
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li>
              <a
                href="#exampledropdownDropdown4"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="icon-interface-windows"></i>Locations
              </a>
              <ul
                id="exampledropdownDropdown4"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showPincodes}
                  className={this.state.pincodes ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-mail"></i>Pincodes
                  </a>
                </li>{" "}
                <li
                  onClick={this.showStores}
                  className={this.state.stores ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-screen"></i>Stores
                  </a>
                </li>
              </ul>
            </li> */}
            {/* <li
              onClick={this.showPromos}
              className={this.state.promos ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Promos
              </a>
            </li>
            <li
              onClick={this.showOffers}
              className={this.state.offers ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Offers
              </a>
            </li>{" "}
            <li
              onClick={this.showSubscriptions}
              className={this.state.subscriptions ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Subscriptions
              </a>
            </li> */}
          </ul>
          <span className="heading"></span>
        </nav>
        <div className="content-inner">
          {this.state.home ? <Root /> : null}
          {this.state.employees ? <Employees /> : null}
          {this.state.delivery ? <Delivery /> : null}
          {this.state.users ? <Users /> : null}
          {this.state.reports ? <Reports /> : null}
          {this.state.itemReports ? <ItemReports /> : null}
          {this.state.categoryReports ? <CategoryReports /> : null}
          {this.state.billReports ? <BillReports /> : null}
          {this.state.execReports ? <ExecutiveReports /> : null}
          {this.state.hourlyReports ? <TodaysReport /> : null}
          {this.state.userReports ? <UserReports /> : null}
          {this.state.bestReports ? <BestReports /> : null}
          {this.state.categories ? <Categories /> : null}
          {this.state.subCategories ? <SubCategories /> : null}
          {this.state.expensesReport ? <ExpensesReportContainer /> : null}
          {this.state.products ? <Products /> : null}
          {this.state.banner ? <Banner /> : null}
          {this.state.appVersions ? <AppVersions /> : null}
          {this.state.pincodes ? <Pincodes /> : null}
          {this.state.stores ? <Stores /> : null}
          {this.state.promos ? <Promos /> : null}
          {this.state.showDeals ? <TakeOrder2 /> : null}
          {this.state.offers ? <Offers /> : null}
          {this.state.subscriptions ? <Subscriptions /> : null}
          {this.state.takeOrder ? <TakeOrder /> : null}
          {this.state.orders ? <Orders /> : null}
          {this.state.expensesMain ? <ExpensesContainer /> : null}
          {this.state.expensesItems ? <Expenses expenses={this.state.expensesItems} expenseCat={this.state.expenseCat} expenseSubCat={this.state.expenseSubCat} /> : null}
          {/* {this.state.expenseCat ? <Expenses expenses={this.state.expensesItems} expenseCat={this.state.expenseCat} expenseSubCat={this.state.expenseSubCat} /> : null} */}
          {/* {this.state.expenseSubCat ? <Expenses expenses={this.state.expensesItems} expenseCat={this.state.expenseCat} expenseSubCat={this.state.expenseSubCat} /> : null} */}
          <Footer />
        </div>
      </div>
    );
  }
}
export default AdminDash;
